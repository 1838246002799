
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import CompetitionService from "../../services/sporteventCalculation/CompetitionService";
import Competition from "../../models/sporteventCalculation/Competition";
import AgeGenderGroup from "../../models/sporteventCalculation/AgeGenderGroup";

@Component({})
export default class CompetitionsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Competition[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "longName",
      sortable: true,
      label: i18n.tc("labels.longname"),
    },
    {
      key: "ageGenderGroup",
      sortable: true,
      label: i18n.tc("sporteventCalculation.agegrendergroup"),
    },
    {
      key: "oetbWienID",
      sortable: true,
      label: "ÖTB-Wien-ID",
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAllCompetitions(): void {
    CompetitionService.getAll().then((items: Competition[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
        this.items.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
      this.loading = false;
    });
  }
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAllCompetitions();
  }

  // CSV Import
  public csvFormData = new FormData();
  // eslint-disable-next-line
  handleFileUpload(event: any) {
    //console.log(event);
    this.csvFormData.append(
      "competitionCsv",
      event.target.files[0],
      event.target.files[0].name
    );
  }

  uploadCsv(): void {
    this.loading = true;
    CompetitionService.uploadCsv(this.csvFormData).then(() => {
      //(response) => {
      //console.log(response);
      this.getAllCompetitions();
      this.loading = false;
    });
  }

  getAgeGenderName(ageGenderGroup: AgeGenderGroup): string {
    ageGenderGroup = new AgeGenderGroup(ageGenderGroup);
    return ageGenderGroup.getName();
  }
}
